@import "_import.scss";

/*-------- チュートリアル動画 -------*/
.popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	opacity: 0;
	visibility: hidden;
	transition: .6s;
  }
  .popup.is-show {
	opacity: 1;
	visibility: visible;
  }
  .popup-inner {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 80%;
	max-width: 800px;
	padding: 55px;
	background-color: $white-blue;
	border-radius: 5px;
	z-index: 2;
  }
  .popup-inner video {
	width: 100%;
	background-color: $gray;
  }
  .popup-inner p{
	  text-align: center;
	  font-size: 18px;
	  margin: 10px 0 10px 0;
  } 
  .close-btn {
	position: absolute;
	right: 0;
	top: 0;
	margin: 10px;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	cursor: pointer;
  }
  .close-btn i {
	font-size: 40px;
	color: #333;
  }
  .black-background {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.8);
	z-index: 1;
	cursor: pointer;
  }